import { Box, BoxProps } from "@chakra-ui/react"
import { IPlayerProps, Player } from "@lottiefiles/react-lottie-player"
import { useEffect, useState } from "react"

import { EAnimation } from "../@types/global_types"

const getLottieAnimationData = (animation: EAnimation) => {
    if (animation === EAnimation.confetti) {
        return import("../public/animations/confetti_lottie.json")
    }
    if (animation === EAnimation.brandLoading) {
        return import("../public/animations/ll_loading.json")
    }
    if (animation === EAnimation.broadcastDot) {
        return import("../public/animations/pulsing_dot.json")
    }
    if (animation === EAnimation.notFound404) {
        return import("../public/animations/404.json")
    }
    if (animation === EAnimation.spinnerBlob) {
        return import("../public/animations/spinner.json")
    }
    if (animation === EAnimation.dripLoading) {
        return import("../public/animations/drip_loading.json")
    }
    if (animation === EAnimation.blackLock) {
        return import("../public/animations/black_lock.json")
    }
    throw new Error(`getLottieAnimationData: unknown animation: ${animation}`)
}

type TLottiePlayerProps = BoxProps &
    Omit<IPlayerProps, "src"> & {
        animation: EAnimation | null
    }

const LottiePlayer = (props: TLottiePlayerProps) => {
    const { animation, ...rest } = props
    const [animationData, setAnimationData] = useState<Record<string, any> | null>(null)

    useEffect(() => {
        if (animation === null) {
            setAnimationData(null)
        } else {
            getLottieAnimationData(animation).then((data) => setAnimationData(data.default))
        }
    }, [animation])

    if (!animationData) {
        // Lottie can't render null src and updating the src doesn't change results
        // so we only mount it when we have the data.
        return null
    }
    return <Box as={Player} src={animationData} {...rest} />
}
export default LottiePlayer
